<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">

        <v-card-title class="d-flex align-center justify-center py-7">

          <!-- brand logo -->
          <router-link
            to="/"
            class="brand-logo d-flex align-center"
          >
            <v-img
              :src="appLogo"
              max-height="230px"
              max-width="230px"
              alt="logo"
              contain
              class="me-3 "
            ></v-img>

          </router-link>
          <!--/ brand logo -->
        </v-card-title>

        <v-row class="auth-row ma-0">
          <v-col
            lg="12"
            class="d-flex align-center auth-bg pb-0"
          >
            <v-row v-if="isSuccessMessageVisible">
              <v-alert type="success" class="mt-10">
                Vielen Dank für Ihre Registrierung. Ihre Daten werden zeitnah von einem unserer Mitarbeiter geprüft.
                Sobald Sie freigeschaltet sind, erhalten Sie eine E-Mail in Ihrem Posteingang.
              </v-alert>
            </v-row>

            <v-row v-else>
              <v-col
                cols="12"
                sm="8"
                md="8"
                lg="12"
                class="mx-auto"
              >
                <v-card flat>
                  <v-card-text>
                    <v-form
                      ref="registerForm"
                      @submit.prevent="handleFormSubmit"
                    >
                      <v-row>

                        <v-col
                          cols="12"
                          md="6"
                          class="py-0"
                        >
                          <v-text-field
                            v-model="form.mainData.vorname"
                            outlined
                            :error-messages="errorMessages.vorname"
                            :rules="[validators.required]"
                            label="Vorname"
                            placeholder="Vorname"
                            hide-details="auto"
                            class="mb-6"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                          class="py-0"
                        >
                          <v-text-field
                            v-model="form.mainData.name"
                            outlined
                            :error-messages="errorMessages.name"
                            :rules="[validators.required]"
                            label="Name"
                            placeholder="Name"
                            hide-details="auto"
                            class="mb-6"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="12"
                          class="py-0"
                        >
                          <v-text-field
                            v-model="form.mainData.firma"
                            outlined
                            :error-messages="errorMessages.firma"
                            :rules="[validators.required]"
                            label="Firma"
                            placeholder="Firma"
                            hide-details="auto"
                            class="mb-6"
                          ></v-text-field>
                        </v-col>


                        <v-col
                          cols="12"
                          md="12"
                          class="py-0"
                        >
                          <v-text-field
                            v-model="form.mainData.email"
                            outlined
                            :error-messages="errorMessages.email"
                            :rules="[validators.required, validators.emailValidator]"
                            label="E-Mail"
                            placeholder="E-Mail"
                            hide-details="auto"
                            class="mb-6"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="12"
                          class="py-0"
                        >
                          <v-text-field
                            v-model="form.mainData.passwort"
                            outlined
                            :type="isPasswordVisible ? 'text' : 'password'"
                            label="Passwort"
                            :error-messages="errorMessages.passwort"
                            placeholder="Password"
                            :rules="[validators.required, validators.passwordValidator]"
                            :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                            hide-details="auto"
                            class="mb-6"
                            @click:append="isPasswordVisible = !isPasswordVisible"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="12"
                          class="py-0"
                        >
                          <v-text-field
                            v-model="form.mainData.passwort_w"
                            outlined
                            :type="isPasswordVisible ? 'text' : 'password'"
                            label="Passwort Wiederholung"
                            :error-messages="errorMessages.passwort_w"
                            placeholder="Password"
                            :rules="[validators.required, validators.passwordValidator]"
                            :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                            hide-details="auto"
                            class="mb-6"
                            @click:append="isPasswordVisible = !isPasswordVisible"
                          ></v-text-field>
                        </v-col>

                        <v-col
                          cols="12"
                          md="12"
                          class="py-0"
                        >
                          <v-checkbox
                            hide-details
                            class="mt-0"
                            v-model="form.mainData.agb1"
                            :rules="[validators.checkboxValidator]"
                          >
                            <template #label>
                              <div class="d-flex align-center flex-wrap">
                                <small class="me-2">
                                  Ich habe die
                                  <a @click="openPdf(agb.default || agb)" >AGB</a>
                                  gelesen und akzeptiere sie.</small>
                              </div>
                            </template>
                          </v-checkbox>
                        </v-col>

                      </v-row>


                      <v-btn
                        block
                        color="primary"
                        type="submit"
                        class="mt-6"
                      >
                        Registrieren
                      </v-btn>
                    </v-form>
                  </v-card-text>

                  <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                    <p class="mb-0 me-2">
                      Sie haben bereits einen Account?
                    </p>
                    <router-link :to="{name:'auth-login'}">
                      Login
                    </router-link>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>


        <v-dialog
          v-model="isAgbDialogVisible"
          width="500px"
        >
          <v-card>
            <v-card-title class="justify-center flex-column">Allgemeine Geschäftsbedingungen (AGB)
            </v-card-title>
            <v-card-text
              class="justify-center flex-column"
            >
              <ol>
                <li>
                  <strong>Geltungsbereich</strong>
                  <p>Diese Allgemeinen Geschäftsbedingungen gelten für alle Verträge, Lieferungen
                    und sonstigen Leistungen.</p>
                </li>
                <li>
                  <strong>Vertragsabschluss</strong>
                  <p>Die Angebote auf unserer Website stellen eine unverbindliche Aufforderung zur
                    Abgabe von Angeboten dar. Der Vertrag kommt erst durch eine ausdrückliche
                    Annahme seitens des Unternehmens zustande.</p>
                </li>
                <!-- Weitere Punkte einfügen... -->
              </ol>
            </v-card-text>
            <v-card-actions class="justify-center flex-column">
              <v-btn

                color="secondary"
                outlined
                @click="isAgbDialogVisible = false"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

      </v-card>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiTwitter,
  mdiGithub,
  mdiGoogle,
  mdiEyeOutline,
  mdiEyeOffOutline
} from '@mdi/js'
// eslint-disable-next-line object-curly-newline
import {
  required,
  emailValidator,
  passwordValidator,
  alphaValidator,
  checkboxValidator
} from '@core/utils/validation'
import {ref, getCurrentInstance} from '@vue/composition-api'
import axios from '@axios'
import {useRouter} from '@core/utils'
import themeConfig from '@themeConfig'
import router from "@/router";

export default {
  setup() {

    const registerForm = ref(null)

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = themeConfig.toastr.defaultTimeout;
    vm.$toastr.defaultPosition = themeConfig.toastr.defaultPosition;

    const {router} = useRouter()

    const form = ref({})
    form.value = {
      mainData: {
        vorname: '',
        name: '',
        firma: '',
        email: '',
        passwort: '',
        passwort_w: '',
        agb1: 0,
      }
    };

    const openPdf = (pdf) => {
      window.open(pdf, '_blank');
    }

    const isPasswordVisible = ref(false)
    const isAgbDialogVisible = ref(false)
    const isSuccessMessageVisible = ref(false)
    const errorMessages = ref([])
    const loading = ref(false)

    const agb = require('@/assets/pdfs/2024-05-quadress-agb.pdf');

    const handleFormSubmit = () => {
      const isFormValid = registerForm.value.validate()
      if (!isFormValid) return
      loading.value = true;

      let fd = new FormData();
      Object.keys(form.value.mainData).forEach(key => {
        fd.append(`mainData[${key}]`, form.value.mainData[key]);
      });

      axios
        .post('/api/registrierung/', fd)
        .then(response => {
          if (response.data.valid === true) {
            isSuccessMessageVisible.value = true;
          } else {
            vm.$toastr.e(response.data.errors[0].errorText, response.data.errors[0].errorTitle);

            errorMessages.value = response.data.errors;
          }
          loading.value = false;
        })
        .catch(error => {
          errorMessages.value = error.response.data.errors
          loading.value = false;
        })
    }

    return {
      form,
      isPasswordVisible,
      isAgbDialogVisible,
      isSuccessMessageVisible,
      errorMessages,
      handleFormSubmit,
      openPdf,
      agb,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
        passwordValidator,
        checkboxValidator,
        alphaValidator,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      // Template Refs
      registerForm,
      loading
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
